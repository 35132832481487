<style scoped>
.login {
  /*background-color: whitesmoke;*/
  /*position: fixed;*/
  height: 110vh;
}

.img-background {
  /*background-image: url("../assets/h5login/bg_h5login.png");*/
  height: 100vh;
  width: 100vw;
  background-size: 120% 120%;
}

.login-form {
  /*background-image: url("../assets/login/bg.png");*/
  background-position: center;
  height: 50vh;
  width: 90vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -45%);
  text-align: center;
}

.login-form-log {
  margin-top: -90px;
  height: 90px;
}

.login-form > .login_title {

}

.login-form-user {
  text-align: left;
  height: 28px;
  background-size: 100% 100%;
}

.login-form > p {
  display: block;
  font-size: 12pt;
  color: #222222;
  font-family: PingFangSC-Regular;
  margin-left: 5%;
  text-align: start;
}

.login_text {
  width: 115px;
  height: 57px;
  font-size: 30px;
  margin-left: 34px;
  margin-top: -50vw;
  font-family: PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 15px;
  position: relative;
}

.login_text_underline {
  margin-left: 34px;
  margin-top: -15vw;
  width: 60px;
  height: 10px;
  background: #F1BD20;
}

.login_text_subtitle {
  width: 121px;
  height: 14px;
  font-size: 14px;
  margin-left: 34px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 8px;
  opacity: 0.6;
}

.split-line {
  width: 82.7vw;
  margin-left: 4.6vw;
  height: 1px;
  background: #9A9A9A;
  opacity: 0.4;
  margin-bottom: 40px;

}

.login-form-user > el-input:focus {
  outline: none;
}

.user_input {
  width: 88.7vw;
  margin-left: 1.6vw;
}

.login-button {
  width: 74.6vw;
  height: 44px;
  background: #F1BD20;
  color: white;
  box-shadow: 0px 6px 27px 0px rgba(241, 189, 32, 0.27);
  border-radius: 5.8vw;
  font-size: 18px;
  margin-top: 30vh;
}

.reg-button {
  width: 70vw;
  height: 18px;
  margin-top:24px;
  font-size: 14px;
  font-family: PingFang SC;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-left: 0vw;
  /*box-shadow: 0px 6px 27px 0px rgba(241, 189, 32, 0.0);*/
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  opacity: 0.7;
  border: none;

}

/deep/ .el-input__inner {
  background-color: transparent;
  border: none;

}

/deep/ .el-form-item__error {
  margin-left: 20px;
  padding-top: 17px;
}

.error {
  line-height: 40px;
  font-size: 15px;
  position: absolute;
  top: 72%;
  left: 44.7%;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  color: red;
}

</style>


<template>
  <div class="login" v-loading.fullscreen.lock="fullscreenLoading">

    <div style="background-color: transparent;">
      <img style="width: 61.5vw;margin-left: 48vw;margin-top: 26px;" src="@/assets/image/login/bg_login_top.png"/>
      <p class="login_text">登录</p>
      <p class="login_text_underline">&emsp;</p>
      <p class="login_text_subtitle">和上金融商户平台</p>

    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">

      <div class="login-form">
        <div class="login_title"></div>


        <el-form-item class="login-form-user" prop="userphone">

          <!--              <img style="height: 14px;margin-left: 16px;margin-bottom: 3px;" src="@/assets/image/pay/icon_amount.png"/>-->
          <!--              <p style="display: inline;margin-left: 5px;font-weight: bold;line-height: 72px;">订单金额：</p>-->
          <el-input class="user_input" id="userphone" v-model="ruleForm.userphone"
                    autocomplete="on" placeholder="请输入账号" type="text" clearable/>
        </el-form-item>
        <div class="split-line"></div>
        <el-form-item class="login-form-user" prop="password">

          <el-input class="user_input" id="userpassword" v-model="ruleForm.password" autocomplete="on"
                    placeholder="请输入密码" type="password" clearable/>
        </el-form-item>
        <div class="split-line"></div>
        <el-button class="login-button" @click="submitForm('ruleForm')">登 录</el-button>
        <el-button class="reg-button" @click="registerUser">注 册</el-button>

        <div v-if="isShow" class="error">{{ err_message }}</div>
      </div>
    </el-form>


  </div>
</template>


<script>

// import {request} from "@/network/network";
// import { ElMessage } from 'element-plus'

import {Dialog} from "vant";

export default {
  name: "login",
  data() {
    return {
      userphone: null,
      password: null,
      isShow: false,
      err_message: null,
      fullscreenLoading: false,
      merchantName: "",
      checked: false,
      ruleForm: {
        userphone: '',
        password: ''
      },
      rules: {
        userphone: [
          {required: true, message: '请输入账户', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
        ]
      },

    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login()//调用登录方法
        } else {
          return false;
        }
      });
    },
    login() {
      let _this = this
      let params = {
        "username": this.ruleForm.userphone,
        "password": this.$md5(this.ruleForm.password),
      }
      // console.log(params)
      Http.post('/frontend/merchant/login', params).then(result => {
        if (result.code === 200) {
          // console.log(result.data)

          // 清理本地存储
          localStorage.setItem("draftId", "")
          localStorage.setItem("templeId", "")
          localStorage.setItem("merchantDraftId", "")
          localStorage.setItem("merchantDraftStatus", "")
          localStorage.setItem("merchantDraftStatusMessage", "")
          // 重设用户信息
          _this.$store.commit('setCurrentUser', result.data);

          _this.$message.success("登录成功！");
          let draftId = result.data.merchantDraftId ? result.data.merchantDraftId : ""
          let merchantDraftStatus = result.data.merchantDraftStatus ? result.data.merchantDraftStatus : ""
          let merchantDraftStatusMessage = result.data.merchantDraftStatusMessage ? result.data.merchantDraftStatusMessage : ""

          localStorage.setItem("merchantDraftStatus", merchantDraftStatus)
          localStorage.setItem("merchantDraftStatusMessage", merchantDraftStatusMessage)
          localStorage.setItem("draftId", draftId)

          _this.$router.push({path: '/h5/home'})

        } else {
          _this.$message.error(result.message || "登录失败，账号或密码错误！")
        }
      }).catch(error => {
        // console.log(error)
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
    registerUser() {
      this.$router.push({path: '/h5/register'})
    },
    reset_pwd() {
    },
    getMerchant() {


    },


  },
  mounted() {
    this.getMerchant()
  },
  created() {
    document.title = "和上金融"
  }

}
</script>